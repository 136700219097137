<template>
  <div class="task-container">
    <div class="head">
      <el-form inline :model="form">
        <el-form-item label="任务id">
          <el-input ref="input" v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item label="任务标签">
          <el-select v-model="form.tag" placeholder="请选择">
            <el-option v-for="tag in tags" :key="tag" :label="tag" :value="tag">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上线状态">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="所有" :value="null"></el-option>
            <el-option
              v-for="item in ONLINE_STATUS"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">筛选</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="addTask">新建任务</el-button>
    </div>
    <el-table
      stripe
      border
      v-loading="loading"
      :data="list"
      style="width: 100%"
      height="calc(100% - 95px )"
      @row-dblclick="editTask"
      @sort-change="sortChange"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="id" label="任务ID" align="center" width="150">
      </el-table-column>
      <el-table-column prop="title" label="任务标题" align="center">
      </el-table-column>
      <el-table-column
        prop="keyword"
        label="气泡关键字"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column prop="pic" label="气泡图片" align="center" width="180">
        <template slot-scope="scope">
          <img style="width: 100%" :src="scope.row.pic" />
        </template>
      </el-table-column>
      <el-table-column
        prop="sysCount"
        label="运营示例数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="userCount"
        label="用户示例数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="tag" label="任务标签" align="center" width="100">
      </el-table-column>
      <el-table-column
        prop="weight"
        label="权重"
        align="center"
        width="100"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="上线状态"
        align="center"
        width="100"
        :formatter="statusFormatter"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      v-loading="dialogLoading"
      class="taskadd-dialog"
      center
      :title="dialogType === 'edit' ? '编辑任务' : '新建任务'"
      :visible.sync="dialogVisible"
      width="500px"
      @close="beforeDialogClose"
    >
      <el-form
        :model="editform"
        ref="editform"
        label-width="100px"
        size="small"
        :rules="rules"
      >
        <el-form-item label="任务ID" prop="id">
          <el-input
            placeholder="自动生成不可更改"
            v-model="editform.id"
            readonly
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="任务标题" prop="title">
          <el-input
            placeholder="请输入标题"
            v-model="editform.title"
            maxlength="20"
            show-word-limit
            clearable
            :disabled="!editable"
          ></el-input>
        </el-form-item>
        <el-form-item label="气泡关键字" prop="keyword">
          <el-input
            placeholder="请输入气泡关键字"
            v-model="editform.keyword"
            maxlength="10"
            show-word-limit
            clearable
            :disabled="!editable"
          ></el-input>
        </el-form-item>
        <el-form-item label="气泡图片" prop="img" class="img-item">
          <el-radio-group
            :disabled="!editable"
            v-model="imgType"
            @change="imgTypeChange"
          >
            <el-radio
              :label="item.value"
              v-for="item in BUBBLE_IMG_TYPE"
              :key="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
          <img
            class="defalut-pic"
            :class="{ disabled: !editable }"
            v-if="imgType === 1"
            :src="DEFAULT_PIC"
          />
          <photo-upload
            :disabled="!editable"
            v-else-if="imgType === 2"
            v-model="editform.img"
            @change="uploadSuccess"
          ></photo-upload>
        </el-form-item>
        <el-form-item label="任务标签" prop="tag" class="tag-item">
          <el-select
            :disabled="!editable"
            v-model="editform.tag"
            :loading="tagCreating"
            placeholder="请选择任务标签"
          >
            <el-option v-for="tag in tags" :key="tag" :label="tag" :value="tag">
            </el-option>
          </el-select>
          <el-input
            :disabled="!editable"
            class="addtag"
            v-model="newTag"
            v-if="tagCreating"
            ref="tag-input"
            clearable
            @blur="tagCreating = false"
            @keyup.enter.native="tagInputComfirm"
          >
          </el-input>
          <el-button
            class="addtag"
            icon="el-icon-edit"
            v-else
            :disabled="!editable"
            @click="createTag"
            >新建标签</el-button
          >
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input
            :disabled="!editable"
            placeholder="请输入权重"
            v-model.number="editform.weight"
            type="number"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="上线状态" prop="status">
          <el-select
            v-model="editform.status"
            placeholder="请选择上线状态"
            class="state-select"
          >
            <el-option
              v-for="item in ONLINE_STATUS"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveTask">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  tagList,
  tagCreation,
  taskList,
  taskCreation,
  taskModification
} from '@/api/loveAdvice';
import { BUBBLE_IMG_TYPE, ONLINE_STATUS, DEFAULT_PIC } from './constant';
import photoUpload from './components/photo-upload.vue';
export default {
  components: { photoUpload },
  data () {
    const rules = {
      title: [{ required: true, trigger: 'blur', message: '标题不能为空' }],
      tag: [{ required: true, trigger: 'change', message: '标签不能为空' }],
      weight: [{ required: true, trigger: 'blur', message: '权重不能为空' }],
      status: [
        { required: true, trigger: 'change', message: '上线状态不能为空' }
      ]
    };
    return {
      rules,
      order: null,
      BUBBLE_IMG_TYPE,
      ONLINE_STATUS,
      DEFAULT_PIC,
      pageSize: 10,
      pageNo: 1,
      total: 0,
      tags: [],
      loading: false,
      dialogVisible: false,
      tagCreating: false,
      tagLoading: false,
      newTag: null,
      imgType: null,
      dialogType: 'add',
      form: {
        id: null,
        tag: null,
        status: null
      },
      list: [],
      dialogLoading: false,
      editable: true,
      img: null,
      editform: {
        id: null,
        title: null,
        keyword: null,
        img: null,
        tag: null,
        weight: 0,
        status: null
      }
    };
  },
  created () {
    this.getTags();
    this.getList();
  },
  methods: {
    beforeDialogClose () {
      this.$refs.editform.resetFields();
      this.editform = {
        id: null,
        title: null,
        keyword: null,
        img: null,
        tag: null,
        weight: 0,
        status: null
      };
      this.imgType = null;
      this.tagCreating = false;
      this.dialogType = '';
      this.editable = true;
      this.uploadState = 0;
    },
    sortChange ({ prop, order }) {
      const orderBy = {
        ascending: 'asc',
        descending: 'desc'
      };
      this.order = order ? `${prop} ${orderBy[order]}` : null;
      this.getList();
    },
    statusFormatter (row, column, cellValue, index) {
      return ONLINE_STATUS.find((i) => i.value === cellValue).label;
    },
    saveTask () {
      if (this.dialogLoading) {
        return;
      }
      this.dialogLoading = true;
      this.$refs.editform.validate(async (valid) => {
        if (valid) {
          try {
            console.log('form:', this.editform);
            let data = {
              title: this.editform.title,
              keyword: this.editform.keyword,
              tag: this.editform.tag,
              weight: this.editform.weight,
              status: this.editform.status
            };
            if (this.editform.img) {
              data.pic = this.editform.img.main_url;
            }
            if (this.dialogType === 'edit') {
              data.id = this.editform.id;
            }
            const resp =
              this.dialogType === 'add'
                ? await taskCreation(data)
                : await taskModification(data);
            console.log('tasksave resp:', resp);
            if (resp.result !== 1) {
              throw new Error(JSON.stringify(resp));
            }
            this.$message({
              type: 'success',
              message: `任务${this.dialogType === 'add' ? '新建' : '编辑'}成功`
            });
            this.dialogVisible = false;
            this.getList();
          } catch (error) {
            console.error(error);
            this.$message({
              type: 'error',
              message: `任务${this.dialogType === 'add' ? '新建' : '编辑'}失败`
            });
          }
        }
        this.dialogLoading = false;
      });
    },
    async getTags () {
      try {
        const resp = await tagList();
        console.log('taglist resp:', resp);
        if (resp.result !== 1) {
          throw new Error(JSON.stringify(resp));
        }
        this.tags = resp.list;
      } catch (error) {
        console.error(error);
        this.$message({
          type: 'error',
          message: '拉取标签列表失败'
        });
      }
    },
    addTask () {
      this.dialogType = 'add';
      this.dialogVisible = true;
    },
    editTask (row) {
      this.dialogType = 'edit';
      this.editform = {
        id: row.id,
        title: row.title,
        keyword: row.keyword,
        img: { main_url: row.pic },
        tag: row.tag,
        weight: row.weight,
        status: row.status
      };
      if (row.status === 1) {
        this.editable = false;
      }
      if (row.pic) {
        this.imgType = row.pic === DEFAULT_PIC ? 1 : 2;
        this.img = this.editform.img;
      }
      this.dialogVisible = true;
    },
    imgTypeChange (imgType) {
      if (imgType) {
        this.editform.img =
          imgType === 1 ? { main_url: DEFAULT_PIC } : this.img;
      }
    },
    uploadSuccess (photo) {
      this.img = photo;
    },
    createTag () {
      (this.newTag = null), (this.tagCreating = true);
      this.$nextTick(() => {
        let tagsInput = this.$refs['tag-input'];
        if (tagsInput) {
          tagsInput.focus();
        }
      });
    },
    async tagInputComfirm () {
      console.log('tagInputComfirm');
      if (this.tagLoading) {
        return;
      }
      this.tagLoading = true;
      if (!this.newTag) {
        this.tagCreating = false;
        this.tagLoading = false;
        return;
      }
      if (this.tags.includes(this.newTag)) {
        this.$message({
          type: 'error',
          message: '标签已存在,新建失败'
        });
        this.tagCreating = false;
        this.tagLoading = false;
        return;
      }
      try {
        const resp = await tagCreation({
          text: this.newTag
        });
        console.log('tagadd resp:', resp);
        if (resp.result !== 1) {
          throw new Error(JSON.stringify(resp));
        }
        let tags = this.tags;
        tags.push(this.newTag);
        this.tags = tags;
        this.$refs['tag-input'].blur();
        this.editform.tag = this.newTag;
        this.$message({
          type: 'success',
          message: '新建标签成功'
        });
      } catch (error) {
        this.tagCreating = false;
        console.error(error);
        this.$message({
          type: 'error',
          message: '新建标签失败'
        });
      }
      this.tagLoading = false;
    },
    handleSizeChange (size) {
      if (size) {
        this.pageSize = size;
        this.pageNo = 1;
        this.getList();
      }
    },
    handleCurrentChange (current) {
      if (current) {
        this.pageNo = current;
        this.getList();
      }
    },
    async getList () {
      console.log(this.form);
      this.loading = true;
      try {
        let data = {
          tag: this.form.tag,
          id: this.form.id,
          order: this.order,
          page: this.pageNo,
          count: this.pageSize,
          status: this.form.status
        };

        const resp = await taskList(data);
        console.log('tasklist resp:', resp);
        if (resp.result !== 1) {
          throw new Error(JSON.stringify(resp));
        }
        this.total = resp.total;
        this.list = resp.list;
      } catch (error) {
        console.error(error);
        this.$message({
          type: 'error',
          message: '拉取任务列表失败'
        });
      }
      this.loading = false;
    }
  }
};
</script>
<style lang="scss">
.task-container {
  background: #fff;
  width: calc(100% - 24px);
  height: calc(100vh - 50px - 12px - 24px);
  padding: 22px;
  box-sizing: border-box;
  margin: 12px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
}
.taskadd-dialog {
  .tag-item {
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-select {
        margin-right: 5px;
        flex-grow: 1;
      }
      .addtag {
        width: 120px;
      }
    }
  }
  .state-select {
    width: 100%;
  }
  .img-item {
    .defalut-pic {
      width: 100px;
      height: 100px;
      display: block;
      object-fit: cover;
      border: 1px #333 dotted;
      border-radius: 4px;
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}
</style>
